import axios from 'axios'
import qs from 'qs'

export function createClient(options = {}) {

  const client = axios.create(options)

  // client.defaults.maxBodyLength = Infinity
  // client.defaults.maxContentLength = Infinity

  // console.log(client.defaults)

  client.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' })

  return client
}