import { createApi } from '@/api'

const api = createApi({
  baseUrl: process.env.VUE_APP_API_URL,
  resources: {
  },
  storage: localStorage,
  language: 'en',
  itemsPerPage: 10,
  // ping: 30000, // see users/counter
})

export default api