import { createClient } from './client'
import { createUser } from './user'
import { createResource } from './resource'
import { utils } from './utils'
import { merge } from 'lodash'
import Model from './model.class'
import Collection from './collection.class'
import Mercure from './mercure.class'

export { utils } from './utils'
export { createStorage } from './storage'

export function createApi(options = {}) {

  const user = createUser(createClient({
    baseURL: options.baseUrl,
  }), options)

  const client = createClient({
    baseURL: options.baseUrl,
  })

  const resources = options.resources || {}
  const debug = options.debug || false
  const itemsPerPage = options.itemsPerPage || 10
  const filters = options.filters || {}
  const pingUrl = options.pingUrl || '/users/ping'
  const mercure = new Mercure(client)
  const storage = options.storage || sessionStorage
  let _lang = options.language || 'nl'

  function setLanguage(language) {
    _lang = language
  }

  client.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${user.state.accessToken}`

    if (!config.params) {
      config.params = {}
    }
    
    if (!config.params['_lang']) {
      config.params['_lang'] = _lang
    }

    return config
  }, error => Promise.reject(error))

  client.interceptors.response.use(response => response, error => {
    if (error && error.response && error.response.status === 401) {
      user.logout()
    }
    return Promise.reject(error)
  })

  if (options.ping) {
    setInterval(() => {
      if (user.state.isLoggedIn) {
        client.get(pingUrl).catch(user.logout)
      }
    }, options.ping)
  }

  function resource(name, options = {}) {
    return createResource(client, name, merge({}, { debug, itemsPerPage }, { filters }, resources[name] || {}, options))
  }

  function model(path, options = {}) {
    return new Model(client, path, options)
  }

  function collection(path, options = {}) {
    return new Collection(client, path, Object.assign({ itemsPerPage, storage }, options))
  }

  return {
    user,
    client,
    resource,
    model,
    collection,
    setLanguage,
    mercure,
    utils,
  }

}



