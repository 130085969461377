import { toRefs, reactive, watch, computed } from 'vue'


export const utils = {

  isEmpty: (value) => {
    return value === undefined ||
           value === null ||
           (typeof value === 'object' && Object.keys(value).length === 0) ||
           (typeof value === 'string' && value.trim().length === 0)
  },


  numberFormat: (number, decimals, decPoint, thousandsSep) => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandsSep === 'undefined') ? '.' : thousandsSep
    const dec = (typeof decPoint === 'undefined') ? ',' : decPoint
    let s = ''
    const toFixedFix = function (n, prec) {
      if (('' + n).indexOf('e') === -1) {
        return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
      } else {
        const arr = ('' + n).split('e')
        let sig = ''
        if (+arr[1] + prec > 0) {
          sig = '+'
        }
        return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
      }
    }
    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || ''
      s[1] += new Array(prec - s[1].length + 1).join('0')
    }
    return s.join(dec)
  },

  trim: (str, charlist) => {
    let whitespace = [
      ' ',
      '\n',
      '\r',
      '\t',
      '\f',
      '\x0b',
      '\xa0',
      '\u2000',
      '\u2001',
      '\u2002',
      '\u2003',
      '\u2004',
      '\u2005',
      '\u2006',
      '\u2007',
      '\u2008',
      '\u2009',
      '\u200a',
      '\u200b',
      '\u2028',
      '\u2029',
      '\u3000'
    ].join('')
    let l = 0
    let i = 0
    str += ''
    if (charlist) {
      whitespace = (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '$1')
    }
    l = str.length
    for (i = 0; i < l; i++) {
      if (whitespace.indexOf(str.charAt(i)) === -1) {
        str = str.substring(i)
        break
      }
    }
    l = str.length
    for (i = l - 1; i >= 0; i--) {
      if (whitespace.indexOf(str.charAt(i)) === -1) {
        str = str.substring(0, i + 1)
        break
      }
    }
    return whitespace.indexOf(str.charAt(0)) === -1 ? str : ''
  },

  rtrim (str, charlist) {
    charlist = !charlist
      ? ' \\s\u00A0'
      : (charlist + '').replace(/([[\]().?/*{}+$^:])/g, '\\$1')
    const re = new RegExp('[' + charlist + ']+$', 'g')
    return (str + '').replace(re, '')
  },


  readFileAsDataUrl(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      try {
        reader.readAsDataURL(file)
      } catch(err) {
        reject(err)
      }
    })
  },

  createLoader() {

    const state = reactive({
      index: 0,
      isActive: computed(() => state.index > 0),
    })

    function show() {
      state.index++
    }

    function hide() {
      state.index = Math.max(0, --state.index);
    }

    function clear() {
      state.index = 0
    }

    function bindRef(ref, inversed = false) {
      watch(ref, (newVal, oldVal) => {
        if (newVal != oldVal) {
          if (newVal || (!newVal && inversed)) {
            show()
          } else {
            hide()
          }
        }
      }, { immediate: true })
    }


    return {
      ...toRefs(state),
      state,
      show,
      hide,
      clear,
      bindRef,
    }

  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },

  isAvailable(parent, child) {
    try {
      const childArray = String(child).split('.');
      let evaluted = parent;
      childArray.forEach((x) => {
        evaluted = evaluted[x];
      });
      return !!evaluted;
    } catch {
      return false;
    }
  },

  parseJson(value, defaultValue = null) {
    try {
      return JSON.parse(value)
    } catch(err) {
      console.log(err)
      return defaultValue
    }
  },

}


