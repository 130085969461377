<template>
  <v-list
    dense
  >
    <template v-for="(item, index) in items">

      <v-list-group
        :key="'item_' + index"
        v-if="item.items && item.items.length && !item.hidden"
        :value="false"
        no-action
      >

        <template v-slot:prependIcon>
          <v-icon small v-text="item.icon"></v-icon>
        </template>

        <template v-slot:appendIcon>
          <v-icon small>fas fa-chevron-down</v-icon>
        </template>

        <template v-slot:activator>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </template>

        <!-- subitems -->
        <template v-for="(subItem, subIndex) in item.items">
          <v-list-item
            v-if="!subItem.hidden"
            :key="'item_' + index + '_' + subIndex"
            :to="subItem.to"
            exact
          >
            <v-list-item-title v-text="subItem.title"></v-list-item-title>
          </v-list-item>
        </template>

      </v-list-group>

      <v-list-item
        v-else-if="!item.hidden"
        :key="index"
        :to="item.to"
        exact
      >
        <v-list-item-icon>
          <v-icon small v-text="item.icon"></v-icon>
        </v-list-item-icon>

        <v-list-item-title>
          {{ item.title }}
          <v-chip
            v-if="item.badge"
            :color="item.badge.color"
            class="ml-1"
            x-small
          >{{ item.badge.title }}</v-chip>
        </v-list-item-title>
      </v-list-item>

    </template>
  </v-list>
</template>


<script>
import { ref, watch } from 'vue'

export default {
  props: {
    value: {},
    items: Array,
  },
  setup(props, context) {

    const drawer = ref(props.value)

    watch(() => props.value, val => {
      drawer.value = val
    })

    watch(drawer, val => {
      context.emit('input', val)
    })

    return {
      drawer,
    }

  },
}
</script>
