import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import nl from 'vuetify/es5/locale/nl'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#32373c', // #003384
        secondary: '#17AF95',
        tertiary: '#19191a', // #19191a
        accent: '#000000',
        background: '#eaeaea',
      },
      dark: {
        primary: '#006eb8',
        secondary: '00972d',
        accent: '#26b3fb',
        tertiary: '#32373c',
        title: '#022e5f',
        'title-grey': colors.blueGrey.lighten2,
        background: '#eaeaea',
      },
    },
  },
  lang: {
    locales: { nl, en },
    current: 'en',
  },
  icons: {
    iconfont: 'fa',
  }
});
