<template>
  <v-container
    fluid
    class="fill-height primary bottom-gradient"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        xs="12"
        sm="8"
        md="5"
        lg="3"

      >
        <v-card
          outlined
          rounded="lg"
          class="pa-16"
        >

          <v-img
            src="/brezo-logo.svg"
            class="mx-14"
          ></v-img>

          <v-form
            class="mt-10"
          >

            <v-text-field
              outlined
              v-model="model.username"
              label="Username"
              prepend-inner-icon="fas fa-user"
              autofocus
            ></v-text-field>

            <v-text-field
              type="password"
              v-model="model.password"
              outlined
              label="Password"
              prepend-inner-icon="fas fa-lock"
              @keyup.enter="login()"
              :error-messages="errorMessages"
            ></v-text-field>

            <v-btn
              class="mt-3"
              color="primary"
              large
              block
              @click="login()"
            >Login</v-btn>

          </v-form>

        </v-card>

      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { inject, ref, reactive, computed } from 'vue'

export default {
  name: 'Login',

  setup() {
    const { user } = inject('api')

    const model = reactive({
      username: null,
      password: null,
    })

    const errors = ref(false)

    const errorMessages = computed(() => {
      return errors.value ? ['Incorrect username or password.'] : []
    })

    function login() {
      errors.value = false
      user.login(model).catch(() => {
        errors.value = true
      })
    }

  
    return {
      model,
      login,
      errorMessages,
    }
  },
}
</script>

<style scoped>

.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 272px);
}

</style>
