import Vue, { provide } from 'vue'
// import './plugins/composition-api'

import App from './App.vue'
import api from './plugins/api'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  setup() {
    provide('api', api)
  },
  render: h => h(App)
}).$mount('#app')

