/* eslint-disable */
import { ref, watch } from 'vue'
import { createStorage } from './storage'
import { debounce, cloneDeep } from 'lodash'

function isString(x) {
  return typeof x === 'string' || x instanceof String
}

function isObject(x) {
  return typeof x === 'object' && x !== null
}

function isArray(x) {
  return Array.isArray(x)
}

function isEmpty(value) {
  return value === undefined ||
         value === null ||
         (typeof value === 'object' && Object.keys(value).length === 0) ||
         (typeof value === 'string' && value.trim().length === 0)
}

export default class Collection {

  constructor(client, path, options = {}) {

    this._client = client
    this._path = path
    this._options = options

    this.items = ref([])
    this.filter = ref({})

    this.page = ref()
    this.itemsPerPage = ref(this._options.itemsPerPage || 25)
    this.sort = ref(null)

    this.storage = createStorage(options.storage || sessionStorage)
    this.store = options.store === true ? `${path}_` + btoa(JSON.stringify(options.params)) : options.store

    this.totalCount = ref()
    this.options = ref({ itemsPerPage: this.itemsPerPage.value })
    this.isSearching = ref(false)

    this._afterFind = this._options.afterFind || (val => val)

    if (this.store) {
      let state = this.storage.getObject(this.store)

      if (state && state.options) {
        this.options.value = state.options
        this.page.value = state.options.page
        this.itemsPerPage.value = state.options.itemsPerPage
        this.sort.value = state.sort
        this.filter.value = state.filter
      }

      if (state && state.total) {
        this.totalCount.value = state.total
      }
    }

    watch(this.options, options => {

      this.page.value = options.page
      this.itemsPerPage.value = options.itemsPerPage
      const sortItems = []
  
      this._log('options', options)
  
      if (isArray(options.sortBy)) {
        for (let index = 0; index < options.sortBy.length; ++index) {
          sortItems.push((options.sortDesc && options.sortDesc[index] ? '-' : '') + options.sortBy[index])
        }
      }
  
      this.sort.value = sortItems.length ? sortItems.join(',') : null
  
      this.search()
    })

  }

  setPath(path) {
    this._path = path
    this.store = null
    return this
  }

  _log() {
    this._options.debug && console.log(this._path, ...arguments)
  }

  _getParams() {

    const params = Object.assign({}, this._options.params)

    // filter
    const _filter = this._getFilter()

    if (Object.keys(_filter).length) {
      params['filter'] = _filter
    }

    if (this.page.value) {
      params['page'] = this.page.value
    }

    params['per-page'] = this.itemsPerPage.value

    if (this.sort.value) {
      params['sort'] = this.sort.value
    }

    return params
  }

  _handleNullFilter(filter) {
    const self = this

    if (isObject(filter)) {
      Object.keys(filter).forEach(key => {
        let value = filter[key]

        // handles { eq: null }
        if (isObject(value) && value['eq'] === null) {
          value = 'NULL'
        }
        
        // handles { neq: null }
        if (isObject(value) && value['neq'] === null) {
          if (!filter['not']) {
            filter['not'] = {}
          }
          filter['not'][key] = 'NULL'
          delete value['neq']
          
          if (Object.keys(value).length === 0) {
            value = undefined
          }
        }

        if (value === undefined) {
          delete filter[key]
        } else {
          filter[key] = self._handleNullFilter(value)
        }
      })
    }

    return filter
  }

  _getFilter() {
    const self = this

    // operatorCondition: lt,gt,lte,gte,eq,neq,in,nin,like => field: { operator: value|[value, ...], ... }
    // nullCondition: field: 'NULL'
    // blockCondition: not => block: { operatorCondition|nullCondition, ... }
    // conjunctionCondition: and,or => conjunction: [{ operatorCondition|nullCondition|conjunctionCondition|blockCondition }, ...]

    // filter: { filterName: 'operator[:field][,field...][!]', ... }
    // filter: { filterName: { rule: 'operator[:field][,field...][!]', ... }, ... }
    // filter: { filterName: { operator: 'operator', field: 'field', skipOnEmpty: true, ... }, ... }

    let paramsFilter =  this._options.params && this._options.params.filter
      ? cloneDeep(this._options.params.filter) 
      : {}

    let optionsFilter = this._options.filter || {}

    paramsFilter['and'] = Object.assign({}, paramsFilter['and'])
    let index = Object.keys(paramsFilter['and']).length

    Object.keys(optionsFilter).forEach(filterName => {
      let filter = optionsFilter[filterName]

      if (isString(filter)) {
        filter = { rule: filter}
      }

      if (filter.rule && isString(filter.rule)) {
        let filterRule = filter.rule
      
        if (filterRule.slice(-1) === '!') {
          filter.skipOnEmpty = false
        }

        filterRule = filterRule.replace(/\s/g, '').replace(/!$/, '')
        let filterParts = filterRule.split(':')
        filter.operator = filterParts[0]
        filter.field = filterParts[1] || filterName
        delete filter.rule
      }

      let skipOnEmpty = true

      if (filter.skipOnEmpty !== undefined) {
        skipOnEmpty = filter.skipOnEmpty
        delete filter.skipOnEmpty
      }

      let filterValue = self.filter.value[filterName] || null

      if (!skipOnEmpty || !isEmpty(filterValue)) {

        if (filter.field && filter.operator) {

          let filterField = filter.field
          let filterOperator = filter.operator
          delete filter.field
          delete filter.operator

          if (filterOperator === 'query') {

            let filterValues = isString(filterValue) ? filterValue.split(' ') : [filterValue]

            filterValues.forEach(filterValue => {

              let orFilters = {}
              let orIndex = 0
              let filterFields = filterField.split(',')

              filterFields.forEach(filterField => {
                let orFilter = {}
                orFilter[filterField] = {}
                orFilter[filterField]['like'] = filterValue
                orFilters[orIndex++] = orFilter
              })

              paramsFilter['and'][index++] = { or: orFilters }
            })

            if (Object.keys(filter).length) {
              paramsFilter['and'][index++] = filter
            }

          } else {

            let filterFields = filterField.split(',')

            if (filterFields.length > 1) {

              let orFilters = {}
              let orIndex = 0
              filterFields.forEach(filterField => {
                let orFilter = {}
                orFilter[filterField] = {}
                orFilter[filterField][filterOperator] = isArray(filterValue) ? Object.assign({}, filterValue) : filterValue
                orFilters[orIndex++] = orFilter
              })

              paramsFilter['and'][index++] = { or: orFilters }

            } else {

              filter[filterField] = {}
              filter[filterField][filterOperator] = isArray(filterValue) ? Object.assign({}, filterValue) : filterValue
  
              paramsFilter['and'][index++] = filter

            }

          }
        }

      }

    })

    if (Object.keys(paramsFilter['and']).length === 0) {
      delete paramsFilter['and']
    }

    return this._handleNullFilter(paramsFilter)
  }

  setFilter(name, value) {
    this.filter.value = Object.assign({}, this.filter.value, { [name]: value })
    return this
  }

  clear() {

    this._log('clear')

    this.items.value = []
  }

  search() {
    const self = this
    this.isSearching.value = true
    const params = this._getParams()

    this._log('search', params)

    if (this.store) {
      this.storage.setObject(this.store, { filter: this.filter.value, options: this.options.value, sort: this.sort.value, total: this.totalCount.value })
    }

    return this._client.get(this._path, { params })
      .then(response => {
        this.clear()
        this._populate(response.data || [], response.headers || {})
        this.isSearching.value = false
        return Promise.resolve(self)
      })
      .catch(error => {
        this.clear()
        this.isSearching.value = 'error'
        return Promise.reject(error)
      })
  }

  all(cb) {
    const params = this._getParams()
    params['per-page'] = null

    this._log('all', params)

    this._client.get(this._path, { params })
      .then(response => {
        this.clear()
        this._populate(response.data || [], response.headers || {})
        cb && cb(this)
      })
      .catch(() => {
        this.clear()
      })

    return this
  }

  debounceSearch(delay = 300) {
    const self = this
    return debounce(self.search.bind(self), delay)
  }

  _populate(items, headers = {}) {

    const self = this

    items.forEach(item => self._afterFind(item))
    this.items.value = items

    this._log('populate', items.length, 'items')

    this.page.value = headers['x-pagination-current-page'] ? Number(headers['x-pagination-current-page']) : this.page.value
    this.itemsPerPage.value = headers['x-pagination-per-page'] ? Number(headers['x-pagination-per-page']) : this.itemsPerPage.value
    this.totalCount.value = headers['x-pagination-total-count'] ? Number(headers['x-pagination-total-count']) : null

    // don't trigger reactivity!
    Object.assign(this.options.value, {
      page: this.page.value,
      itemsPerPage: this.itemsPerPage.value,
    })

  }

  forEach(cb) {
    this.items.value.forEach(cb)
  }


}