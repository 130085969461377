/* eslint-disable */

import { EventSourcePolyfill } from 'event-source-polyfill';
import { createClient } from './client'


export default class Mercure {

  constructor(client) {
    this._client = client
    this._mercure = {}
  }

  _getMercure() {

    const currentTime = Math.floor((new Date).getTime() / 1000)

    if (this._mercure.token_time && currentTime < (this._mercure.token_time + this._mercure.token_lifetime)) {
      return Promise.resolve(this._mercure)
    }

    return this._client.get('users/mercure').then(response => {
      this._mercure = response.data || {}
      return Promise.resolve(this._mercure)
    })
  }

  
  _getTime() {
    const d = new Date()
    return Math.floor(d.getTime() / 1000)
  }

  subscribe(topic, cb) {
    const topics = Array.isArray(topic) ? topic : [topic]

    this._getMercure().then(mercure => {
      const url = new URL(mercure.hub_url)
      topics.forEach(topic => url.searchParams.append('topic', mercure.namespace + '/' + topic))

      const eventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: 'Bearer ' + mercure.subscriber_token
        }
      })

      eventSource.onmessage = e => {
        let data = e.data
  
        try {
          data = JSON.parse(e.data)
        } catch(err) { /* console.log(err) */ }
  
        cb(data, e)
      }

      
    })
  }

  publish(topic, data) {

    this._getMercure().then(mercure => {
      const client = createClient({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + mercure.publisher_token
        }
      })

      client.post(mercure.hub_url, client.defaults.paramsSerializer({
        topic: mercure.namespace + '/' + topic,
        data: JSON.stringify(data),
      }))

    })

  }


}